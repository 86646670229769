import React from "react";
import header from "../assets/images/header.jpg";
import headerMobile from '../assets/images/header-mobile.jpg'
import {Box, useMediaQuery, useTheme} from "@mui/material";

export const Visual = () => {
    const theme= useTheme()
    const matches= useMediaQuery(theme.breakpoints.up("md"))
  return(
      <Box>
          <img src={matches ? header : headerMobile} style={{width:'100%', borderBottom:"solid black 5px"}}/>
      </Box>
  )
}
