import React from "react";
import {Box} from "@mui/material";
import advisors from '../assets/images/advisors.jpg'
import advisorsMobile from '../assets/images/advisor-mobile.jpg'

export const Advisors = () => {
  return(
      <Box sx={{width:'100%', my:5}}>
          <Box sx={{textAlign:'center'}}>
              <p style={{fontWeight:'700', fontSize:'1.5rem', margin:0}}>
                Speakers
              </p>
          </Box>
          <Box sx={{width:'100%', display: {xs: 'none', md:'block'}}}>
              <img src={advisors} style={{width:'100%'}}/>
          </Box>
          <Box sx={{width:'100%', display: {xs: 'block', md:'none'}}}>
              <img style={{width:'100%'}} src={advisorsMobile}/>
          </Box>
      </Box>
  )
}
