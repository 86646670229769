import {createTheme} from "@mui/material";

export const iedTheme = createTheme({
    typography: {
        fontFamily: 'Gellix'
    },
    components:{
      MuiTextField:{
          styleOverrides:{
              root:{
                  borderBottom:'1px solid black'
              }
          }
      }
    },
    palette:{
        primary:{
            main:'#e50201'
        }
    }
})
