import React, {useState} from "react";
import {Box, Button, CircularProgress, Container, Grid, TextField, Typography} from "@mui/material";
import axios from "axios";
import header from '../assets/images/header.jpg'
import advisor from '../assets/images/advisors.jpg'
import advisorMobile from '../assets/images/advisor-mobile.jpg'
import {Claim} from "../components/Claim";
import {Advisors} from "../components/Advisors";
import {Visual} from "../components/Visual";

const mappaturaDati = [
    {
        id: 'nome',
        label: 'Nome',
        type: 'text'
    },
    {
        id: 'cognome',
        label: 'Cognome',
        type: 'text'
    },
    {
        id: 'email',
        label: 'Email',
        type: 'email'
    },
    {
        id: 'corso',
        label: 'Corso IED',
        type: 'text'
    },
    {
        id: 'sede',
        label: 'Sede IED',
        type: 'text'
    },
]

const defaultDati = {
    nome: '',
    cognome: '',
    email: '',
    corso: '',
    sede: ''
}

export const Form = () => {
    const [dati, setDati] = useState(defaultDati)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("C'è stato un errore si prega di riprovare")

    const handleChangeDati = (e) => {
        setDati({...dati, [e.target.name]: e.target.value})
    }

    const sendDati = async (e) => {
        e.preventDefault()
        setError(false)
        setMessage("C'è stato un errore si prega di riprovare")
        setSuccess(false)
        setSending(true)
        await axios.post('https://ied-api-xqldtrhcnq-ey.a.run.app/register',dati)
            .then((res) => {
                setDati(defaultDati)
                setSuccess(true)
                window.location.href = res.data.url
            })
            .catch((error) => {
                setMessage(error.response.data.message)
                setError(true)
            })
        setSending(false)
    }

    return (
        <Box>
            <Visual/>
            <Container sx={{my: 4}}>
                <Claim/>
                <form onSubmit={sendDati}>
                    <Grid container spacing={4}>
                        {mappaturaDati.map(d =>
                            <Grid item xs={12} md={4}>
                                <TextField key={d.id} required sx={{width: '100%'}} variant='standard' name={d.id} label={d.label} type={d.type}
                                           onChange={handleChangeDati}/>
                            </Grid>
                        )}
                    </Grid>
                    <Box sx={{textAlign: 'center', mt: 4}}>
                        {(sending) ? <CircularProgress/> :
                            !success &&
                            <Button type={"submit"} sx={{borderRadius: '0rem', fontWeight:'700'}} size={"large"}
                                    variant={"contained"}>Registrati</Button>
                        }
                        {success && <Typography sx={{mt:2}} variant={'h6'} color='green'>
                            La registrazione è avvenuta con successo.
                            <br/>
                            Riceverai una mail a ridosso dell'evento con tutte le informazioni per partecipare.
                        </Typography>}
                        {error && <Typography sx={{mt:2}} color='red' variant={'h6'}>{message}</Typography>}
                    </Box>
                </form>
                <Advisors/>
            </Container>
        </Box>

    )
}
