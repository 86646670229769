import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Container, Typography} from "@mui/material";
import {Visual} from "../components/Visual";
import axios from "axios";
import {useParams} from "react-router-dom";
import {red} from "@mui/material/colors";

export const ZoomPage = () => {
    const {hash} = useParams()
    const [zoomLink, setZoomLink] = useState('')
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getLink().then(()=>setLoading(false))
    },[])

    const getLink = async () => {
        await axios.get('https://ied-api-xqldtrhcnq-ey.a.run.app/zoom/'+hash)
            .then(r => setZoomLink(r.data.url))
            .catch(e => setError(true))
    }

  return(
      <Box>
          <Visual/>
          <Container sx={{my: 4, textAlign:'center'}}>
              {loading ?
                  <CircularProgress/> :
                  error ?
                  <Box>
                      <Typography variant={"h5"} sx={{mb: 2}} fontWeight={'bold'} color={red[400]}>
                          Il link non è valido
                      </Typography>
                  </Box> :
                  <Box>
                      <Typography variant={"h5"} sx={{mb: 2}} fontWeight={'bold'}>
                          Clicca il tasto qui sotto per entrare nella chiamata Zoom
                      </Typography>
                      <Button variant={"contained"} size={"large"} sx={{fontWeight: 'bold'}} href={zoomLink} target={"_blank"}>
                          Entra su zoom
                      </Button>
                  </Box>
              }
          </Container>
      </Box>
  )
}
