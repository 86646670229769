import React from "react";
import visual from '../assets/images/home.jpg'

export const Index = () => {
  return(
      <div style={{background:'linear-gradient(90deg, rgba(0,0,0,1) 81.99%, rgba(255,255,255,1) 81.99%)', height:'100vh'}}>
        <img style={{width:'100%'}} src={visual}/>
      </div>
  )
}
