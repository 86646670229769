import './App.css';
import {Route, Routes} from "react-router-dom";
import {Index} from "./pages";
import {Form} from "./pages/Form";
import {ZoomPage} from "./pages/ZoomPage";


function App() {
    return (
        <>
            <Routes>
                <Route path='/' element={<Index/>}/>
                <Route path='/advisoryboard2022' element={<Form/>}/>
                <Route path='/advisoryboard2022/live/:hash' element={<ZoomPage/>}/>
            </Routes>
        </>
    );
}

export default App;
