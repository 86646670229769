import React from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";

export const Claim = () => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
  return(
      <Box sx={{mb: 2}}>
          <p style={{fontSize: matches ? '1.4rem' : '1rem', fontWeight:'700', margin:0}}>
              La tua opinione conta! Partecipa live al confronto sul tema - molto attuale - della responsabilità nella comunicazione. Il 30 settembre ne parleremo con i 12 membri dell’Advisory Board IED Comunicazione. Potrai intervenire esprimendo il tuo parere sui quesiti che emergeranno e - anche grazie al tuo contributo -  costruiremo un Decalogo condiviso  della Comunicazione Responsabile.
          </p>
      </Box>
  )
}
